import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Button,
  DialogActions,
  Divider,
  Checkbox,
  FormControlLabel,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import { theme } from "../../utils/CustomTheme";
import {
  AutogenerateModeOption,
  AutogenerateOption,
  AutogenerateOptions,
  AutogenerateOptionsDialogProps,
  AutogeneratePostOption,
  LicenseGroupOptions,
  ApplySet,
} from "./BiblesInterfaces";
import { useEffect, useState, useCallback } from "react";
import { Media as MediaInterface } from "../Media/MediaInterfaces";
import { styles } from "../StockNumbers/styles";

type GroupedData = Record<string, AutogenerateOption>;

const MUSIC_NON_DRAMA = "1";
const MODE_ID_AUDIO_DRAMA = "audio_drama";
const MODE_ID_AUDIO_NON_DRAMA = "audio_non_drama";
const MODE_ID_TEXT = "text";
const MODE_ID_VIDEO = "video";

const SET_TYPE_CODE_AUDIO = "audio";
const SET_TYPE_CODE_AUDIO_DRAMA = "audio_drama";
const SET_TYPE_CODE_TEXT_PLAIN = "text_plain";
const SET_TYPE_CODE_TEXT_USX = "text_usx";
const SET_TYPE_CODE_TEXT_JSON = "text_json";
const SET_TYPE_CODE_VIDEO_STREAM = "video_stream";

const EXTENT_C = "C";
const EXTENT_N = "N";
const EXTENT_O = "O";

const MEDIA_MODES: AutogenerateModeOption[] = [
  {
    name: "Audio Drama",
    id: MODE_ID_AUDIO_DRAMA,
    value: false,
    hasBeenGenerated: false,
    canBeGenerated: true,
  },
  {
    name: "Audio Non-Drama",
    id: MODE_ID_AUDIO_NON_DRAMA,
    value: false,
    hasBeenGenerated: false,
    canBeGenerated: true,
  },
  {
    name: "Video",
    id: MODE_ID_VIDEO,
    value: false,
    hasBeenGenerated: false,
    canBeGenerated: true,
  },
  {
    name: "Text",
    id: MODE_ID_TEXT,
    value: false,
    hasBeenGenerated: false,
    canBeGenerated: true,
  },
];

const initialAutogenerateOptions: AutogenerateOptions = {
  media: [
    {
      stockNumber: "",
      modes: [...MEDIA_MODES],
    },
  ],
  licenseGroup: {
    sameLicenseOTNT: true,
    sameLicenseAudioDramaAndAudio: true,
  },
};

const checkMediaGeneration = (
  stockNumber: string,
  data: MediaInterface[],
  setTypeCodes: string[],
  suffix?: string,
): boolean => {
  // If no prefix is provided, just check if any media matches the setTypeCodes.
  if (!suffix) {
    return setTypeCodes.every((code) => {
      // Check that the media has the same mediaType and that the extended value of the stocknumber matches the media.
      // For example, if the stocknumber extension is "N" (e.g., N2AKA/UUU), it will validate that N media has been generated.
      // Also, if the stocknumber extension is "C" (e.g., C2AKA/UUU), it will validate that both N and O media have been generated.
      const primaryExists = data.some(
        (item) =>
          item.mediaType === code &&
          item.id[6] &&
          stockNumber[0] &&
          item.id[6] === stockNumber[0],
      );
      const derivedNExists = data.some(
        (item) =>
          item.mediaType === code &&
          item.id[6] &&
          stockNumber[0] &&
          stockNumber[0].startsWith(EXTENT_C) &&
          item.id[6] === EXTENT_N,
      );
      const derivedOExists = data.some(
        (item) =>
          item.mediaType === code &&
          item.id[6] &&
          stockNumber[0] &&
          stockNumber[0].startsWith(EXTENT_C) &&
          item.id[6] === EXTENT_O,
      );

      return primaryExists || (derivedNExists && derivedOExists);
    });
  }

  // If a suffix is provided, check that:
  // 1. A record exists without the suffix.
  // 2. A corresponding record exists with the suffix.
  return setTypeCodes.every((code) => {
    const primaryExists = data.some(
      (item) => item.mediaType === code && !item.id.endsWith(suffix),
    );
    const derivedExists = data.some(
      (item) => item.mediaType === code && item.id.endsWith(suffix),
    );
    return primaryExists && derivedExists;
  });
};

const extractMediaByModeId = (
  data: MediaInterface[],
  modeId: string,
): MediaInterface[] => {
  switch (modeId) {
    case MODE_ID_AUDIO_DRAMA:
      return data.filter((media: MediaInterface) =>
        [SET_TYPE_CODE_AUDIO_DRAMA].some((item) => media.mediaType === item),
      );
    case MODE_ID_AUDIO_NON_DRAMA:
      return data.filter((media: MediaInterface) =>
        [SET_TYPE_CODE_AUDIO].some((item) => media.mediaType === item),
      );
    case MODE_ID_TEXT:
      return data.filter((media: MediaInterface) =>
        [
          SET_TYPE_CODE_TEXT_PLAIN,
          SET_TYPE_CODE_TEXT_USX,
          SET_TYPE_CODE_TEXT_JSON,
        ].some((item) => media.mediaType === item),
      );
    case MODE_ID_VIDEO:
      return data.filter((media: MediaInterface) =>
        [SET_TYPE_CODE_VIDEO_STREAM].some((item) => media.mediaType === item),
      );
    default:
      return [];
  }
};

const AutogenerateOptionsDialog = ({
  onClose,
  open,
  bibleId,
  disabled,
  mediaRecords: data,
}: AutogenerateOptionsDialogProps) => {
  const [options, setOptions] = useState<AutogenerateOptions>({
    ...initialAutogenerateOptions,
  });

  const updateModesForStockNumber = useCallback(
    (stockNumber: string, mediaRecords: MediaInterface[]) => {
      const modesTemplate = JSON.parse(JSON.stringify(MEDIA_MODES));

      const updatedModes = modesTemplate.map((mode: AutogenerateModeOption) => {
        switch (mode.id) {
          case MODE_ID_AUDIO_DRAMA:
            // Directly use checkMediaGeneration for both primary and derived checks
            mode.hasBeenGenerated = checkMediaGeneration(
              stockNumber,
              mediaRecords,
              [SET_TYPE_CODE_AUDIO_DRAMA],
            );
            // If the stocknumber is N1, it will only generate N1 audio filesets.
            if (stockNumber[1] && stockNumber[1] === MUSIC_NON_DRAMA) {
              mode.canBeGenerated = false;
            }
            break;
          case MODE_ID_AUDIO_NON_DRAMA:
            mode.hasBeenGenerated = checkMediaGeneration(
              stockNumber,
              mediaRecords,
              [SET_TYPE_CODE_AUDIO],
            );
            break;
          case MODE_ID_TEXT:
            mode.hasBeenGenerated = checkMediaGeneration(
              stockNumber,
              mediaRecords,
              [SET_TYPE_CODE_TEXT_PLAIN],
            );
            break;
          case MODE_ID_VIDEO:
            mode.hasBeenGenerated = checkMediaGeneration(
              stockNumber,
              mediaRecords,
              [SET_TYPE_CODE_VIDEO_STREAM],
            );
            break;
          default:
            break;
        }
        mode.value = mode.hasBeenGenerated;
        return mode;
      });
      return updatedModes;
    },
    [],
  );

  useEffect(() => {
    if (data === undefined || data.length === 0) {
      return;
    }

    const stocknumbersGrouped: GroupedData = data.reduce(
      (acc: GroupedData, item: MediaInterface) => {
        if (!acc[item.stocknumber]) {
          acc[item.stocknumber] = {
            stockNumber: item.stocknumber,
            isInMonday: item.stocknumberLoaded,
            modes: updateModesForStockNumber(item.stocknumber, [item]),
            mediaRecords: [item],
          };
        } else {
          acc[item.stocknumber].mediaRecords?.push(item);
          acc[item.stocknumber].modes = updateModesForStockNumber(
            item.stocknumber,
            acc[item.stocknumber].mediaRecords ?? [],
          );
        }
        return acc;
      },
      {},
    );

    setOptions({
      media: Object.values(stocknumbersGrouped),
      licenseGroup: {
        sameLicenseOTNT: true,
        sameLicenseAudioDramaAndAudio: true,
      },
    });
  }, [data, updateModesForStockNumber]);

  const updateCheckbox = (portionId: string, modeId: string) => {
    setOptions((prevState: AutogenerateOptions) => {
      const optionsCopy = {
        ...prevState,
        media: prevState.media.map((portion) => {
          // Check if the current portion matches
          if (portion.stockNumber === portionId) {
            return {
              ...portion,
              modes: portion.modes.map((mode) => {
                // Check if the current mode matches
                if (mode.id === modeId) {
                  return {
                    ...mode,
                    value: !mode.value,
                  };
                }
                return mode;
              }),
            };
          }
          return portion;
        }),
      };
      return optionsCopy;
    });
  };

  const updateRadio = (radioKey: string) => {
    setOptions((prevState: AutogenerateOptions) => {
      const optionsCopy = {
        ...prevState,
        licenseGroup: {
          ...prevState.licenseGroup,
          [radioKey]:
            !prevState.licenseGroup[radioKey as keyof LicenseGroupOptions],
        },
      };
      return optionsCopy;
    });
  };

  const selectAll = () => {
    setOptions((prevState: AutogenerateOptions) => {
      const updatedMedia = prevState.media.map(
        (portion: AutogenerateOption) => ({
          ...portion,
          modes: portion.modes.map((mode: AutogenerateModeOption) => ({
            ...mode,
            value: true,
          })),
        }),
      );

      return {
        ...prevState,
        media: updatedMedia,
      };
    });
  };

  const handleGenerate = () => {
    const licenseGroupOptions: string[] = [];
    if (
      options.licenseGroup.sameLicenseOTNT === false ||
      options.licenseGroup.sameLicenseAudioDramaAndAudio === false
    ) {
      if (!options.licenseGroup.sameLicenseOTNT) {
        licenseGroupOptions.push("sameLicenseOTNT");
      }
      if (!options.licenseGroup.sameLicenseOTNT) {
        licenseGroupOptions.push("sameLicenseAudioDramaAndAudio");
      }
    }

    const applySets: ApplySet[] = options.media?.reduce(
      (acm: ApplySet[], autogenerateOption: AutogenerateOption) => {
        // Check if there is at least one option that has been selected and not already generated for a specific stock number.
        if (
          autogenerateOption.modes.some(
            (mode: AutogenerateModeOption) =>
              mode.value === true && !mode.hasBeenGenerated,
          )
        ) {
          acm.push({
            stocknumber: autogenerateOption.stockNumber,
            mode: autogenerateOption.modes.reduce(
              (acc: string[], item: AutogenerateModeOption): string[] => {
                if (item.value === true && !item.hasBeenGenerated) {
                  acc.push(item.id);
                }
                return acc;
              },
              [],
            ),
          });
        }
        return acm;
      },
      [],
    );

    const selectedOptions: AutogeneratePostOption = {
      applySets,
      sameLicenseOTNT: options.licenseGroup.sameLicenseOTNT,
      sameLicenseAudioDramaAndAudio:
        options.licenseGroup.sameLicenseAudioDramaAndAudio,
    };
    onClose(true, selectedOptions);
  };

  const handleCancel = () => {
    onClose(false, {} as AutogeneratePostOption);
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background.default,
        },
      }}
    >
      <DialogTitle>
        Generate Options
        <Typography variant="body2">
          Select what data should be generated for {bibleId}.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {options.media[0]?.modes?.map(
                  (mode: AutogenerateModeOption) => (
                    <TableCell
                      key={`options_media_modes_${mode.name}`}
                      sx={{ textAlign: "center" }}
                    >
                      {mode.name}
                    </TableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {options.media?.map((portion: AutogenerateOption) => (
                <TableRow key={`options_media_${portion.stockNumber}`}>
                  <TableCell
                    style={portion.isInMonday ? styles.greenBackground : {}}
                  >
                    {portion.stockNumber}
                  </TableCell>
                  {portion.modes.map((mode: AutogenerateModeOption) => (
                    <TableCell
                      key={`portion_modes_${mode.name}`}
                      sx={{ textAlign: "center" }}
                    >
                      <FormControlLabel
                        key={mode.id}
                        control={
                          mode.hasBeenGenerated ? (
                            <Tooltip
                              title={extractMediaByModeId(
                                portion.mediaRecords ?? [],
                                mode.id,
                              )
                                .map((mediaRecord) => mediaRecord.id)
                                .join(", ")}
                              disableInteractive
                            >
                              <span>
                                <Checkbox
                                  checked={mode.value}
                                  disabled={mode.hasBeenGenerated}
                                />
                              </span>
                            </Tooltip>
                          ) : (
                            <Checkbox
                              checked={mode.value}
                              disabled={
                                mode.hasBeenGenerated || !mode.canBeGenerated
                              }
                            />
                          )
                        }
                        onChange={() =>
                          updateCheckbox(portion.stockNumber, mode.id)
                        }
                        label=""
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              <TableRow>
                <TableCell>License Group</TableCell>
                <TableCell colSpan={2} sx={{ textAlign: "center" }}>
                  <Typography variant={"body2"}>
                    Is NT license group same as OT?
                  </Typography>
                  <RadioGroup
                    row
                    value={options.licenseGroup.sameLicenseOTNT}
                    onChange={() => updateRadio("sameLicenseOTNT")}
                    name="sameLicenseOTNT"
                    sx={{ marginLeft: "80px" }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio size="small" />}
                      label="Y"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio size="small" />}
                      label="N"
                    />
                  </RadioGroup>
                </TableCell>
                <TableCell colSpan={2} sx={{ textAlign: "center" }}>
                  <Typography variant={"body2"}>
                    Same license group for audio drama and audio non drama?
                  </Typography>
                  <RadioGroup
                    row
                    value={options.licenseGroup.sameLicenseAudioDramaAndAudio}
                    onChange={() =>
                      updateRadio("sameLicenseAudioDramaAndAudio")
                    }
                    name="sameLicenseAudioDramaAndAudio"
                    sx={{ marginLeft: "80px" }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio size="small" />}
                      label="Y"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio size="small" />}
                      label="N"
                    />
                  </RadioGroup>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          sx={{ marginRight: "50px" }}
          onClick={selectAll}
          color="secondary"
          disabled={disabled}
        >
          Select All
        </Button>
        <Button onClick={handleGenerate} color="success" disabled={disabled}>
          Generate
        </Button>
        <Button
          onClick={handleCancel}
          color="secondary"
          autoFocus
          disabled={disabled}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AutogenerateOptionsDialog;
