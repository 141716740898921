import React, { useEffect, useState, useCallback } from "react";
import {
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
  TextField,
  Stack,
  Tooltip,
} from "@mui/material";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Check,
  Clear,
} from "@mui/icons-material";
import { StockNumber } from "./StockNumberInterfaces";
import { styles } from "./styles";
import { useUserInfo } from "../../provider/UserProvider";

export interface StockNumberRowProps {
  initialData: StockNumber;
  handleInputChange: (
    stockNumber: StockNumber,
    action: string,
  ) => (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}

export const StockNumberRow = (props: StockNumberRowProps) => {
  const [stockNumber, setStockNumber] = useState<StockNumber>(
    props.initialData,
  );

  const [editingPseudonym, setEditingPseudonym] = useState<string>("");
  const [pseudonym, setPseudonym] = useState<string>("");

  const { canAdminMedia } = useUserInfo();

  const cancelEdits = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      setEditingPseudonym("");
    },
    [setEditingPseudonym],
  );

  const handlePseudonymChange = useCallback(
    (stockNumberId: string) =>
      (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ): void => {
        setEditingPseudonym(stockNumberId);

        if (!event.target?.value) {
          setPseudonym("");
          return;
        }
        setPseudonym(event.target.value);
      },
    [setEditingPseudonym, setPseudonym],
  );

  useEffect(() => {
    setStockNumber(props.initialData);
    setPseudonym(props.initialData?.pseudonym);
    setEditingPseudonym("");
  }, [props.initialData]);

  const sentToMondayCheckboxDisabled =
    stockNumber.archived && !stockNumber.isInMonday;

  return (
    <TableRow>
      <TableCell sx={[stockNumber.isInMonday ? styles.greenBackground : null]}>
        {stockNumber.id}
      </TableCell>
      <TableCell sx={{ width: "250px" }} align="center">
        <TextField
          value={pseudonym}
          onChange={handlePseudonymChange(stockNumber.id)}
          name="pseudonym"
          size="small"
          fullWidth
          disabled={!canAdminMedia}
        />
      </TableCell>
      <TableCell sx={{ width: "75px" }} align="center">
        {canAdminMedia && editingPseudonym === stockNumber.id && (
          <Stack direction="row" spacing={0}>
            <Tooltip title="Save Changes">
              <IconButton
                onClick={props.handleInputChange(
                  { ...stockNumber, pseudonym: pseudonym.trim() },
                  "updatePseudonym",
                )}
              >
                <Check color="success" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel Changes">
              <IconButton onClick={cancelEdits}>
                <Clear color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </TableCell>
      <TableCell align="center">
        <IconButton
          onClick={props.handleInputChange({ ...stockNumber, pseudonym: pseudonym.trim() }, "sendToMonday")}
          disabled={!canAdminMedia || sentToMondayCheckboxDisabled}
        >
          {stockNumber.isInMonday ? (
            <CheckBox color="secondary" fontSize="small" />
          ) : (
            <CheckBoxOutlineBlank color="secondary" fontSize="small" />
          )}
        </IconButton>
      </TableCell>
      <TableCell align="center">
        <RadioGroup
          row
          value={stockNumber.archived}
          onChange={props.handleInputChange({ ...stockNumber, pseudonym: pseudonym.trim() }, "archive")}
          name="archived"
          style={styles.centerCellContent}
        >
          <FormControlLabel
            value={true}
            control={<Radio size="small" disabled={!canAdminMedia} />}
            label="Y"
          />
          <FormControlLabel
            value={false}
            control={<Radio size="small" disabled={!canAdminMedia} />}
            label="N"
          />
        </RadioGroup>
      </TableCell>
    </TableRow>
  );
};
