import { useGetClient } from "../utils/HttpCommon";

export interface StockNumberResponse {
  data: string;
}

export interface StockNumberUpdateParameters {
  archive: boolean;
  sendToMonday: boolean;
  pseudonym: string;
}

export interface StockNumberPostParams {
  bibleId: string;
  stockNumber: string;
}

const useStockNumberService = () => {
  const { http } = useGetClient();

  const Update = async (
    params: StockNumberUpdateParameters,
    id: string,
  ): Promise<string> => {
    // Replace the '/' with '_' to avoid issues with the API path
    const stockNumber = id.replace("/", "_");
    const response = await http.patch<StockNumberResponse>(
      `/stocknumber/${encodeURIComponent(stockNumber)}`,
      params,
    );

    const { data } = response;
    const { data: responseData } = data;

    return responseData;
  };

  const Post = async (params: StockNumberPostParams): Promise<string> => {
    const response = await http.post<StockNumberResponse>(
      "/stocknumber",
      params,
    );

    const { data } = response;
    const { data: responseData } = data;

    return responseData;
  };

  return {
    Update,
    Post,
  };
};

export default useStockNumberService;
