import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid2,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { AxiosError, isAxiosError } from "axios";
import { StockNumber, StockNumbersProps } from "./StockNumberInterfaces";
import { styles } from "./styles";
import { StockNumberRow, StockNumberRowProps } from "./StockNumberRow";
import { Media } from "../Media/MediaInterfaces";
import { StockNumberIds } from "./constants";
import { useGetMediaList } from "../../hooks/useGetMediaList";
import useStockNumberService, {
  StockNumberPostParams,
  StockNumberUpdateParameters,
} from "../../services/StockNumberService";
import { useUserInfo } from "../../provider/UserProvider";

const calculateStockNumber = (prefix: string, bibleId: string): string => {
  return prefix + bibleId.slice(0, -3) + "/" + bibleId.slice(-3);
};

const filterStockNumbers = (
  bibleId: string,
  currentStocknumbers: StockNumber[],
  stockNumberIds: string[],
): StockNumber[] => {
  return stockNumberIds.reduce(
    (accumulator: StockNumber[], id: string): StockNumber[] => {
      if (
        currentStocknumbers.every(
          (stocknumber: StockNumber) => !stocknumber.id.includes(id),
        )
      ) {
        accumulator.push({
          id: calculateStockNumber(id, bibleId),
          pseudonym: "",
          archived: false,
          isInMonday: false,
        });
      }
      return accumulator;
    },
    [],
  );
};

export const StockNumbers = ({
  bibleId,
  showMessage,
  showArchived,
  handleShowArchived,
}: StockNumbersProps) => {
  const { Post, Update } = useStockNumberService();
  const [openSelectStockId, setOpenSelectStockId] = useState<boolean>(false);
  const [currentStocknumber, setCurrentStocknumber] =
    useState<StockNumber | null>(null);
  const [stockNumbers, setStockNumbers] = useState<StockNumber[]>([]);
  const [isCreatingStocknumber, setIsCreatingStocknumber] =
    useState<boolean>(false);
  const [isStockNumberFetching, setIsStocknumberFetching] =
    useState<boolean>(false);
  const { canAdminPermissions } = useUserInfo();

  const handleSelectId = async () => {
    if (currentStocknumber) {
      try {
        const params: StockNumberPostParams = {
          bibleId,
          stockNumber: currentStocknumber.id,
        };

        setIsCreatingStocknumber(true);
        const response = await Post(params);
        setIsCreatingStocknumber(false);

        if (response === "success") {
          refetch();
        }
      } catch (ex: unknown) {
        if (isAxiosError(ex)) {
          if (ex.response?.data?.error) {
            showMessage(ex.response.data.error);
          } else {
            console.error("Error message:", ex.message);
          }
        }

        setIsCreatingStocknumber(false);
      }
    }

    setOpenSelectStockId(false);
  };

  const addStockNumber = (): void => {
    setCurrentStocknumber({} as StockNumber);
    setOpenSelectStockId(true);
  };

  const changeStocknumber = (
    _: React.SyntheticEvent<Element, Event>,
    newStocknumber: StockNumber | null,
  ): void => {
    setCurrentStocknumber(newStocknumber);
  };

  const { isFetching, data, refetch } = useGetMediaList({
    selectedBible: bibleId,
  });

  useEffect(() => {
    if (bibleId === "0") {
      setStockNumbers([]);
    }
  }, [bibleId]);

  useEffect(() => {
    if (data !== undefined && data !== null) {
      const stockNumberSet = new Set<string>();
      const result: StockNumber[] = [];
      const snResult: StockNumber[] = data?.map((x) =>
        convertMediaToStockNumber(x),
      );

      snResult?.forEach((x) => {
        if (!stockNumberSet.has(x.id) && x.id) {
          result.push(x);
          stockNumberSet.add(x.id);
        }
      });

      setStockNumbers(result);
    } else {
      setStockNumbers([]);
    }
  }, [data]);

  const convertMediaToStockNumber = (media: Media): StockNumber => {
    const result: StockNumber = {
      id: media.stocknumber,
      pseudonym: media.pseudonym,
      archived: media.archived,
      isInMonday: media.stocknumberLoaded ?? false,
    };

    return result;
  };

  const updateStockNumber = (stockNumber: StockNumber) => {
    const updatedStockNumbers = stockNumbers.map((x) => {
      if (x.id === stockNumber.id) {
        return stockNumber;
      }
      return x;
    });

    setStockNumbers(updatedStockNumbers);
  };

  const handleInputChange =
    (stockNumber: StockNumber, action: string) =>
    async (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      let archive = false;
      let sendToMonday = false;
      let pseudonym = "";

      if (
        event.type === "change" &&
        action === "archive" &&
        (event.target as HTMLInputElement).value !== undefined
      ) {
        archive = (event.target as HTMLInputElement).value === "true";
        pseudonym = stockNumber.pseudonym;
      }

      if (event.type === "click" && action === "sendToMonday") {
        sendToMonday = !stockNumber.isInMonday;
        pseudonym = stockNumber.pseudonym;
      }

      if (event.type === "click" && action === "updatePseudonym") {
        sendToMonday = stockNumber.isInMonday;
        archive = stockNumber.archived;
        pseudonym = stockNumber.pseudonym;
      }

      const request: StockNumberUpdateParameters = {
        sendToMonday: sendToMonday,
        archive: archive,
        pseudonym: pseudonym,
      };

      try {
        setIsStocknumberFetching(true);
        const response = await Update(request, stockNumber.id);
        setIsStocknumberFetching(false);

        if (response === "success") {
          updateStockNumber({
            ...stockNumber,
            archived: request.archive,
            isInMonday: request.sendToMonday,
          });
          refetch();
        }
      } catch (error: unknown) {
        let errorMessage = "An error occurred";

        if (error instanceof AxiosError && error.response) {
          errorMessage = error.response.data?.error || errorMessage;
        } else if (error instanceof Error) {
          errorMessage = error.message;
        }

        setIsStocknumberFetching(false);
        showMessage(errorMessage);
      }
    };

  const handleCloseDialog = () => {
    setOpenSelectStockId(false);
  };

  const toggleShowArchived = useCallback(
    (): void => handleShowArchived(!showArchived),
    [showArchived, handleShowArchived],
  );

  return (
    <>
      <Backdrop sx={styles.backdrop} open={isFetching || isStockNumberFetching}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog fullWidth={true} open={openSelectStockId}>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            New Stock Number
          </Typography>
          <Autocomplete
            renderInput={(props) => <TextField {...props} />}
            options={filterStockNumbers(bibleId, stockNumbers, StockNumberIds)}
            getOptionLabel={(option: StockNumber) =>
              option?.id ? option?.id : ""
            }
            isOptionEqualToValue={(option: StockNumber, value: StockNumber) =>
              option?.id === value?.id
            }
            autoFocus
            value={currentStocknumber?.id ? currentStocknumber : null}
            onChange={changeStocknumber}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSelectId}
            disabled={!currentStocknumber?.id || isCreatingStocknumber}
            color="primary"
          >
            Confirm
          </Button>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Grid2 container justifyContent="space-between" alignItems="baseline">
        <Grid2 size={9}>
          <Typography variant="h6" sx={styles.subSectionTitle}>
            Stock Numbers
          </Typography>
        </Grid2>
        <Grid2 size={3} sx={styles.alignRight}>
          <Button
            color="secondary"
            variant="outlined"
            sx={{ marginRight: "10px" }}
            onClick={toggleShowArchived}
          >
            {showArchived ? "Hide Archived" : "Show Archived"}
          </Button>
        </Grid2>
        <Grid2 size={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell align="center">Pseudonym</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">Send To Monday</TableCell>
                  <TableCell align="center">Archive</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stockNumbers?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} sx={{ opacity: ".7" }}>
                      No Results.
                    </TableCell>
                  </TableRow>
                )}
                {stockNumbers?.length > 0 &&
                  stockNumbers.map((item) => {
                    if (showArchived || !item.archived) {
                      const props: StockNumberRowProps = {
                        initialData: item,
                        handleInputChange,
                      };
                      return <StockNumberRow key={item.id} {...props} />;
                    } else {
                      return null;
                    }
                  })}
                <TableRow>
                  <TableCell>
                    <Button
                      disabled={!bibleId || !canAdminPermissions}
                      onClick={addStockNumber}
                    >
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid2>
      </Grid2>
    </>
  );
};
